import {Flex, FlexProps} from '@styled-system/jsx';

type BackgroundImageProps = {
  src: string
} & FlexProps;

const BackgroundImage = ({src, children, ...rest}: BackgroundImageProps) => {
  return <Flex
    w="full"
    style={{backgroundImage: `url(${src})`}}
    alignItems="center"
    backgroundSize="cover"
    backgroundPosition="center"
    {...rest}
  >
    {children}
  </Flex>
}

export default BackgroundImage;
